<template>
  <div>
    <van-cell-group  inset style="margin-top: 10px;padding: 10px;">
  <van-cell style="background-color: #DDEFFF" title="道路" :value="item.road" />
  <van-cell title="类型" :value="item.type==='YS'?'雨水':'污水'" />
   <van-cell title="物探点号" :value="item.geophysical_prospecting_point" />
   <van-cell title="经度" :value="item.lng" />
   <van-cell title="纬度" :value="item.lat" />
   <van-cell title="管线材质" :value="item.pipe_material" />
   <van-cell title="管径或断面尺寸(mm)" :value="item.pipe_diameter" />
   <van-cell title="附属物" :value="item.appendage" />
  <!-- <van-cell title="水表号" :value="item.water_number" />
  <van-cell title="地址" :value="item.address" />
  <van-cell title="手机号" :value="item.phone" />
  <van-cell title="上次抄表数" :value="`${item.ton}吨`" />
  <van-cell title="上次抄表时间" :value="item.createtimel" />
  <van-cell title="本次抄表数" :value="`${item.ton}吨`" />
  <van-cell title="用水吨数" :value="`${item.use_ton}吨`" /> -->
  <!-- <van-cell title="支付状态" :value="item.status==='wait'?'待支付':'已经支付'" /> -->
  <!-- <van-cell title="本次抄表时间" :value="item.createtimef" /> -->
  <!-- <van-cell title="村委" :value="item.region_name" />
  <van-cell title="地址" :value="item.address" />-->
  <!-- <van-cell title="备注" :value="item.memo" /> -->
  <div style="margin:0px 16px 0px 16px;">
  <!-- <van-button plain  type="info" size="small" block  @click="$router.push(`/recordsinfo/${item.id}`)" >更多详情</van-button> -->
</div>
</van-cell-group>

  </div>

</template>

<script>
export default {
  name: 'RecordslistItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
