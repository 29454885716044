import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('@/views/Login')
const Layout = () => import('@/views/Layout')
const Index = () => import('@/views/Index')
const Network = () => import('@/views/Network')
const Cover = () => import('@/views/Cover')
const Coverlist = () => import('@/views/Coverlist')
const User = () => import('@/views/User')//
const Maintenance = () => import('@/views/Maintenance')
const Maintenancelist = () => import('@/views/Maintenancelist')
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/login', component: Login },
  // { path: '/repairapply/:id', component: Repairapply },
  { path: '/network', component: Network },
  { path: '/cover', component: Cover },
  { path: '/coverlist', component: Coverlist },
  { path: '/maintenance', component: Maintenance },
  { path: '/maintenancelist', component: Maintenancelist },
  // { path: '/repairapplylist', component: Repairapplylist },
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/index', component: Index },
      // { path: '/clock', component: Clock },
      // { path: '/exam/:id', component: Exam },
      { path: '/user', component: User }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  document.title = '管网'
  // }
  next()
})

export default router
