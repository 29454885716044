<template>
  <div>
    <van-cell-group  inset style="margin-top: 10px;padding: 10px;">

  <van-cell style="background-color: #DDEFFF" title="申请人" :value="item.truename" />
  <van-cell title="备注" :value="item.remark" />
  <!-- <van-button plain  type="info" size="small" block  @click="$router.push(`/recordsinfo/${item.id}`)" >导航</van-button> -->
  <!-- <van-cell title="类型" :value="item.type==='YS'?'雨水':'污水'" />
   <van-cell title="物探点号" :value="item.geophysical_prospecting_point" />
   <van-cell title="经度" :value="item.lng" />
   <van-cell title="纬度" :value="item.lat" />
   <van-cell title="管线材质" :value="item.pipe_material" />
   <van-cell title="管径或断面尺寸(mm)" :value="item.pipe_diameter" />
   <van-cell title="附属物" :value="item.appendage" /> -->
  <!-- <van-cell title="水表号" :value="item.water_number" />
  <van-cell title="地址" :value="item.address" />
  <van-cell title="手机号" :value="item.phone" />
  <van-cell title="上次抄表数" :value="`${item.ton}吨`" />
  <van-cell title="上次抄表时间" :value="item.createtimel" />
  <van-cell title="本次抄表数" :value="`${item.ton}吨`" />
  <van-cell title="用水吨数" :value="`${item.use_ton}吨`" /> -->
  <!-- <van-cell title="支付状态" :value="item.status==='wait'?'待支付':'已经支付'" /> -->
  <!-- <van-cell title="本次抄表时间" :value="item.createtimef" /> -->
  <!-- <van-cell title="村委" :value="item.region_name" />
  <van-cell title="地址" :value="item.address" />-->
  <!-- <van-cell title="备注" :value="item.memo" /> -->
  <div style="margin:0px 16px 0px 16px;">
    <van-row gutter="20">
  <van-col span="8"><van-button plain  type="info" size="small"   @click="abc(item.lat,item.lng)" >导航</van-button></van-col>
  <van-col span="8"></van-col>
  <van-col span="8"> <van-button plain  type="info" size="small"   @click="abcd(item.id)" >维修完毕</van-button></van-col>
</van-row>

</div>
</van-cell-group>

  </div>

</template>

<script>
import { change } from '@/api/index'
export default {
  name: 'RecordslistItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    abc (lat, lng) {
      // <a href="http://api.map.baidu.com/marker?location=纬度,经度&title=所在位置名称&content=所在位置的简介（可选）&output=html">百度地图</a>
      window.location = 'https://api.map.baidu.com/marker?location=' + lat + ',' + lng + '&title=所在位置名称&content=所在位置的简介（可选）&output=html'
    },
    async abcd (ids) {
      console.log(ids)
      const res = await change({ id: ids, state: 'D' })
      try {
        if (res.code === 1) {
          // console.log(res)

          // console.log(getToken())
          this.$toast.success(res.msg)
          // this.$router.push('/maintenancelist')
          // this.$router.back()
          window.location.reload()
        }
        this.$toast.fail(res.msg)
      } catch (e) {
        this.$toast.fail(e.response.data.message)
      }
    }

  }
}
</script>

<style>

</style>
