import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/utils/vant-ui'
import BaiduMap from 'vue-baidu-map'
import CoverlistItem from '@/components/CoverlistItem'
import MaintenancelistItem from '@/components/MaintenancelistItem'

Vue.component('CoverlistItem', CoverlistItem)

Vue.component('MaintenancelistItem', MaintenancelistItem)
Vue.use(BaiduMap, {

  ak: 'bzPvMGtp8d5khAzBBH26UYBsaGyX0mhs'
})

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
