import request from '@/utils/request'
// export const register = (data) => {
//   return request.post('/api/user/registeruser', data)
// }

// export const bindstaff = data => request.post('/api/user/bindstaff', data)
// export const bindConsumer = data => request.post('/api/user/bindconsumer', data)
// export const bindConsumers = data => request.post('/api/user/bindconsumers', data)

export const getCover = () => { return request('/api/index/cover') }
export const getLine = () => { return request('/api/index/line') }
export const addWay = data => request.post('/api/way/add', data)
export const getWay = () => { return request('/api/way/index') }
export const getNetWorkList = () => { return request('/api/way/networklist') }
export const getMaintenanceList = () => { return request('/api/way/maintenancelist') }

export const change = params => request.get('/api/way/change', { params })
